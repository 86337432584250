<template>
    <el-card>
        <div class="supplier-block">
            <div class="supplier-title flex space-between align-items">
                <h3>所属供应商</h3>
                <router-link :to="{ name: 'productsDetails' }" class="more flex align-items">
                    查看供应商详情<i class="el-icon-arrow-right"></i></router-link>
            </div>
            <div class="supplier-info">
                <el-image src="https://picsum.photos/236" lazy></el-image>
            </div>
            <div class="supplier-name">XXXX供应商</div>
            <div class="supplier-desc flex align-items justify-content text-center">
                <dl>
                    <dt>253个</dt>
                    <dd><i class="iconfont icon-zhuangbei"></i>装备数量</dd>
                </dl>
                <dl>
                    <dt>9.3</dt>
                    <dd><i class="iconfont icon-koubei"></i>口碑评分</dd>
                </dl>
            </div>
        </div>
    </el-card>
</template>

<script>


export default {
    name: "supplierBlock",
    data () {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.supplier-block {
    .supplier-title {
        h3 {
            font-size: 24px;
            ;
        }

        .more {
            color: rgba(46, 48, 51, .6);
            font-size: 16px;
            text-decoration: none;
        }
    }

    .supplier-info {
        margin-top: 30px;
        text-align: center;

        /deep/.el-image {
            width: 263px;
            height: 263px;
            text-align: center;
            margin: auto;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }

    .supplier-name {
        color: #2E3033;
        font-size: 18px;
        margin-top: 15px;
        text-align: center;
    }

    .supplier-desc {
        margin-top: 30px;
        color: #2E3033;

        dl {
            dt {
                font-size: 18px;
            }

            dd {
                font-size: 16px;
                margin-top: 10px;

                i {
                    margin-right: 5px;
                    color: #EF312A;
                }
            }

            &+dl {
                margin-left: 30px;
            }
        }
    }
}
</style>