import { render, staticRenderFns } from "./supplierBlock.vue?vue&type=template&id=06dd2792&scoped=true"
import script from "./supplierBlock.vue?vue&type=script&lang=js"
export * from "./supplierBlock.vue?vue&type=script&lang=js"
import style0 from "./supplierBlock.vue?vue&type=style&index=0&id=06dd2792&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06dd2792",
  null
  
)

export default component.exports